<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Conditions </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <div v-else>
          <div class="mt-3">
            <b-form-radio-group v-model="isSelected" @change="setCondition">
              <b-row>
                <b-col
                  ><b-form-radio :value="2">Unconditional</b-form-radio></b-col
                >
                <b-col><b-form-radio :value="1">Custom</b-form-radio></b-col>
              </b-row>
            </b-form-radio-group>
          </div>
          <div v-if="isSelected === 1">
            <b-row class="mt-3">
              <b-col md="4">
                <label>User Type <span class="text-error">*</span></label>
              </b-col>
              <b-col md="8">
                <label
                  >Show Information <span class="text-error">*</span></label
                >
              </b-col>
            </b-row>

            <b-row class="mt-3" v-for="(item, index) in form" :key="index">
              <b-col md="4">
                <InputSelectAutomation
                  v-bind:options="conditionList"
                  :value="item.condition_type_id"
                  :v-model="item.condition_type_id"
                  :indexCondition="index"
                  @onDataChange="selectCondition"
                  valueField="id"
                  textField="name"
                />
              </b-col>
              <b-col md="8">
                <b-row v-if="item.condition_type_id === 1">
                  <b-col md="10">
                    <InputSelectAutomation
                      v-bind:options="memberLevel"
                      :value="item.condition_value"
                      :v="v.$each.$iter[index].condition_value"
                      :isValidate="v.$each.$iter[index].condition_value.$error"
                      :noPleaseSelect="noPleaseSelect"
                      v-model="item.condition_value"
                      :indexCondition="index"
                      @onDataChange="selectMemberLevel"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="item.condition_type_id === 2">
                  <b-col md="10">
                    <InputSelectAutomation
                      v-bind:options="gender"
                      :value="item.condition_value"
                      :noPleaseSelect="noPleaseSelect"
                      :v="v.$each.$iter[index].condition_value"
                      :isValidate="v.$each.$iter[index].condition_value.$error"
                      v-model="item.condition_value"
                      :indexCondition="index"
                      @onDataChange="selectGender"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    item.condition_type_id === 3 ||
                    item.condition_type_id === 4 ||
                    item.condition_type_id === 5 ||
                    item.condition_type_id === 6
                  "
                >
                  <b-col md="5">
                    <InputSelectAutomation
                      v-bind:options="operatorList"
                      :value="item.operation_type_id"
                      :v="v.$each.$iter[index].operation_type_id"
                      :isValidate="
                        v.$each.$iter[index].operation_type_id.$error
                      "
                      :noPleaseSelect="noPleaseSelect"
                      v-model="item.operation_type_id"
                      :indexCondition="index"
                      @onDataChange="selectOperation"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                  <b-col md="5">
                    <b-form-input
                      v-model="item.condition_value"
                      :placeholder="
                        item.condition_type_id === 6
                          ? 'Purchase Amount'
                          : 'Valid Days '
                      "
                      type="number"
                      :class="
                        v.$each.$iter[index].condition_value.$error
                          ? 'border-error'
                          : ''
                      "
                    ></b-form-input>
                    <div
                      v-if="v.$each.$iter[index].condition_value.$error"
                      class="text-error"
                    >
                      Please input.
                    </div>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    item.condition_type_id === 8 || item.condition_type_id === 9
                  "
                >
                  <b-col md="4">
                    <InputSelectAutomation
                      v-bind:options="operatorList"
                      :value="item.operation_type_id"
                      :v="v.$each.$iter[index].operation_type_id"
                      :isValidate="
                        v.$each.$iter[index].operation_type_id.$error
                      "
                      :noPleaseSelect="noPleaseSelect"
                      v-model="item.operation_type_id"
                      :indexCondition="index"
                      @onDataChange="selectOperation"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                  <b-col md="6">
                    <div class="input-container">
                      <datetime
                        type="datetime"
                        :class="
                          v.$each.$iter[index].condition_value.$error
                            ? 'date-picker mb-0 border-error'
                            : 'date-picker mb-0'
                        "
                        :input-style="styleDatetime"
                        v-model="item.condition_value"
                        placeholder="DD/MM/YYYY (HH:MM)"
                        format="dd/MM/yyyy (HH:mm)"
                        value-zone="Asia/Bangkok"
                        ref="transferDateStart"
                      >
                      </datetime>
                      <div
                        :class="'icon-container text-right'"
                        @click="$refs.transferDateStart.isOpen = true"
                      >
                        <font-awesome-icon
                          icon="calendar-alt"
                          :class="'pointer color-primary'"
                          color="#B41BB4"
                        />
                      </div>
                    </div>
                    <div
                      v-if="v.$each.$iter[index].condition_value.$error"
                      class="text-error"
                    >
                      Please input.
                    </div>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="item.condition_type_id === 7">
                  <b-col md="10">
                    <InputSelectAutomation
                      v-bind:options="privilegeList"
                      :value="item.condition_value"
                      :v="v.$each.$iter[index].condition_value"
                      :isValidate="v.$each.$iter[index].condition_value.$error"
                      :noPleaseSelect="noPleaseSelect"
                      v-model="item.condition_value"
                      :indexCondition="index"
                      @onDataChange="selectPrivilegeCode"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>

                <b-row class="no-gutters" v-if="item.condition_type_id === 10">
                  <b-col md="10" class="justify-content-start">
                    <UploadFileSendMessage
                      textFloat="File"
                      placeholder="Please Choose File"
                      format="excel"
                      name="file"
                      text="*Please upload only file .xlsx less than 10 MB"
                      isRequired
                      v-on:onFileChange="onFileChange"
                      :indexCondition="index"
                      v-on:delete="deleteFile"
                      :fileName="filename"
                      id="uploadfile_sendmessage"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-button
                      variant="light"
                      v-if="form.length > 1"
                      @click="removeCondition(index)"
                    >
                      <b-icon size="sm" icon="trash-fill" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="mt-3">
              <button @click.prevent="addRuleCondition" class="btn-add">
                <font-awesome-icon icon="plus-square" class="mr-2" />
                <span class="add-new">Create a New List</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              :disabled="isLoading"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitCondition"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
import UploadFileSendMessage from "@/components/automation/segmentation/UploadFileSendMessage";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    InputSelectAutomation,
    UploadFileSendMessage,
    OtherLoading,
  },
  props: {
    privilegeList: {
      required: true,
      type: Array,
    },
    conditionList: {
      required: true,
      type: Array,
    },
    memberLevel: {
      required: true,
      type: Array,
    },
    gender: {
      required: true,
      type: Array,
    },
    operatorList: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
    formMain: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      isSelected: this.selected,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    addRuleCondition() {
      this.form.push({
        condition_type_id: this.conditionList[0].id,
        operation_type_id: null,
        condition_value: null,
        condition_type_name: this.conditionList[0].name,
      });
    },
    selectOperationTypeId(...value) {
      this.form[value[1]].operation_type_id = value[0];
      this.form[value[1]].operation_type_name = value[2];
    },
    selectMemberLevel(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    selectPrivilegeCode(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    removeCondition(index) {
      this.form.splice(index, 1);
    },
    selectGender(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    selectCondition(...value) {
      this.form[value[1]].condition_type_id = value[0];
      this.form[value[1]].condition_type_name = value[2];
    },
    selectOperation(...value) {
      this.form[value[1]].operation_type_id = value[0];
      this.form[value[1]].operation_type_name = value[2];
    },
    submitCondition() {
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("settingCondition", this.isSelected);
          this.hide();
        } else {
          this.v.$touch();
          if (this.v.$error) {
            return;
          }
          this.$emit("settingCondition", this.isSelected);
          this.hide();
        }
      } else {
        this.$swal("Please set conditions", {
          icon: "warning",
        });
      }
    },
    setCondition() {
      if (this.isSelected === 1) {
        if (this.form.length < 1) {
          this.form.push({
            condition_type_id: this.conditionList[0].id,
            operation_type_id: null,
            condition_value: null,
            condition_type_name: this.conditionList[0].name,
          });
        }
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    onFileChange(...file) {
      this.filename = file[0].name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        this.formMain.import_condition = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.checkFormFile(file);
      };
    },
    async checkFormFile(file) {
      this.isLoading = true;
      let body = {
        excel_file: this.formMain.import_condition,
      };
      await this.$store.dispatch("checkFileExcel", body);
      const data = this.$store.state.automation.stateCheckFileExcel;
      if (data.result === 1) {
        if (data.detail > 0) {
          this.setFormFile(file);
        } else {
          this.isLoading = false;
          this.formMain.import_condition = "";
          this.filename = null;
          const msg = "Please check the information throughly";
          this.$swal(msg, {
            icon: "warning",
          });
        }
      } else {
        this.isLoading = false;
        this.formMain.import_condition = "";
        this.filename = null;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    setFormFile(file) {
      this.isLoading = false;
      this.form[file[1]].condition_value = file[0].name;
      this.form[file[1]].operation_type_id = 3;
    },
    deleteFile(value) {
      this.filename = null;
      this.formMain.import_condition = "";
      this.isDisable = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
</style>
