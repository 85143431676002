<template>
  <div style="border: none;">
    <div v-if="settingValue === 2">
      <div>Condition : Unconditional</div>
    </div>
    <div v-else>
      <div v-if="display === 1">
        <div v-for="(item, index) in this.form.list_condition" :key="index">
          <span v-show="false">{{ item }}</span>
          <b-row v-if="item.condition_type_id === 1">
            <b-col cols="3">{{ item.condition_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600 text-left">
              <span> {{ valueOfmember(item.condition_value) }}</span>
            </b-col>
          </b-row>
          <b-row
            v-if="item.condition_type_id === 2 || item.condition_type_id === 7"
          >
            <b-col cols="3">{{ item.condition_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600 text-left"
              >{{
                item.condition_type_id === 7
                  ? valueOfPrivilege(item.condition_value)
                  : item.condition_value
              }}
            </b-col>
          </b-row>
          <b-row
            v-if="
              item.condition_type_id === 3 ||
              item.condition_type_id === 4 ||
              item.condition_type_id === 5 ||
              item.condition_type_id === 6 ||
              item.condition_type_id === 8 ||
              item.condition_type_id === 9
            "
          >
            <b-col cols="3">{{ item.condition_type_name }} : </b-col>
            <b-col cols="9" class="font-w-600 text-left">
              <span class="mr-3">{{ item.operation_type_name }}</span>
              <span>{{
                item.condition_type_id === 8 || item.condition_type_id === 9
                  ? $moment(item.condition_value).format("DD/MM/YYYY hh:mm:ss")
                  : item.condition_value
              }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else-if="display === 2">
        <div v-for="(action, i) in this.form.list_action" :key="i">
          <span v-show="false">{{ action }}</span>
          <b-row v-if="action.action_type_id === 1">
            <b-col cols="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600">{{
              valueOfRedeem(action.action_value)
            }}</b-col>
          </b-row>
          <b-row v-if="action.action_type_id === 2">
            <b-col cols="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600"
              >{{ action.action_value }} Point</b-col
            >
          </b-row>
        </div>
      </div>
      <div v-else-if="display === 4">
        <div v-for="(action, i) in this.form.action" :key="i">
          <span v-show="false">{{ action }}</span>
          <b-row v-if="action.action_type_id === 1">
            <b-col cols="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600">{{
              valueOfRedeem(action.action_value)
            }}</b-col>
          </b-row>
          <b-row v-if="action.action_type_id === 2">
            <b-col cols="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600"
              >{{ action.action_value }} Point</b-col
            >
          </b-row>
        </div>
      </div>
      <div v-else-if="display === 3">
        <div v-for="(item, index) in this.form.condition" :key="index">
          <span v-show="false">{{ item }}</span>
          <b-row v-if="item.condition_type_id === 1">
            <b-col cols="3">{{ item.condition_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600">
              <span class="mr-3">{{ item.operation_type_name }}</span>
              <span>{{ valueOfmember(item.condition_value) }}</span>
            </b-col>
          </b-row>
          <b-row
            v-if="item.condition_type_id === 2 || item.condition_type_id === 7"
          >
            <b-col cols="3">{{ item.condition_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600">{{
              item.condition_type_id === 7
                ? valueOfPrivilege(item.condition_value)
                : item.condition_value
            }}</b-col>
          </b-row>
          <b-row
            v-if="
              item.condition_type_id === 3 ||
              item.condition_type_id === 4 ||
              item.condition_type_id === 5 ||
              item.condition_type_id === 6 ||
              item.condition_type_id === 8 ||
              item.condition_type_id === 9
            "
          >
            <b-col cols="3">{{ item.condition_type_name }} : </b-col>
            <b-col cols="9" class="font-w-600">
              <span class="mr-3">{{ item.operation_type_name }}</span>
              <span>{{
                item.condition_type_id === 8 || item.condition_type_id === 9
                  ? $moment(item.condition_value).format(
                      "DD/MM/YYYY hh:mm:ss A"
                    )
                  : item.condition_value
              }}</span>
            </b-col>
          </b-row>
          <b-row v-if="item.condition_type_id === 10">
            <b-col cols="3">{{ item.condition_type_name }} :</b-col>
            <b-col cols="9" class="font-w-600">{{
              item.condition_value
            }}</b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
      type: Object,
    },
    display: {
      required: true,
      type: Number,
    },
    memberLevel: {
      required: false,
    },
    settingValue: {
      required: true,
      type: Number,
    },
    privilegeList: {
      required: false,
    },
    redeemCodeList: {
      required: false,
    },
  },
  methods: {
    valueOfRedeem(val) {
      let value = this.redeemCodeList.find((el) => el.id == val);
      return value ? value?.name : val;
    },
    valueOfmember(val) {
      let value = this.memberLevel.find((el) => el.id == val);
      return value?.name;
    },
    valueOfPrivilege(val) {
      let value = this.privilegeList.find((el) => el.id == val);
      return value ? value?.name : val;
    },
  },
};
</script>

<style lang="scss" scoped>
.font-w-600 {
  font-weight: 600;
}
</style>
