<template>
  <div :class="!edit ? 'card-commu' : 'card-commu-edit'">
    <div class="my-2 font-w-600">{{ title }}</div>
    <div class="container-card b-white" v-if="!edit">
      <div class="vertical-center">
        <div>No Items</div>
      </div>
    </div>
    <div v-else class="container-card scroll-content">
      <div v-for="(item, index) in value" :key="index">
        <div v-if="item.communication_type_id === 3">
          <div v-for="(line, i) in item.detail_list" :key="i">
            <div v-for="(list, i) in line.communication_value" :key="i">
              <div
                v-if="list.value || list.image_url"
                class="container-card-list mb-2"
              >
                <b-row
                  v-if="parseInt(line.message_type_id) === 8"
                  cols="6"
                  cols-lg="4"
                >
                  <b-col class="mt-2 text-center px-0">
                    <img class="crop-img" :src="list.image_url" />
                  </b-col>
                </b-row>
                <div v-else>
                  <!-- <div v-for="(list,i) in line.communication_value" :key="i"> -->
                  <div v-if="list.value">{{ list.value }}</div>
                  <div
                    v-if="
                      parseInt(line.message_type_id) === 12 ||
                      parseInt(line.message_type_id) === 7
                    "
                    class="mt-2"
                  >
                    <img class="crop-img" :src="list.image_url" />
                  </div>
                  <div
                    v-if="parseInt(line.message_type_id) === 9"
                    class="mt-2 font-w-600"
                  >
                    IMAGE MAP
                  </div>
                  <!-- </div> -->
                </div>

                <div v-if="line.format_list">
                  <div
                    class="bg-button mt-2"
                    v-for="(format, j) in line.format_list"
                    :key="j"
                  >
                    <font-awesome-icon icon="circle" />
                    {{ format.option_name }}
                  </div>
                </div>
              </div>
              <div class="mt-3 text-edit-communication">
                <button
                  class="btn-edit mr-2"
                  @click.prevent="sideBarLine"
                  :disabled="disableStatus"
                >
                  <font-awesome-icon icon="pencil-alt" class="mr-2" />
                  <span class="text-underline">Edit</span>
                </button>
                <button
                  class="btn-edit mr-2"
                  v-if="item.detail_list.length === 1"
                  @click.prevent="deleteLine"
                  :disabled="disableStatus"
                >
                  <font-awesome-icon icon="trash-alt" class="mr-2" />
                  <span class="text-underline">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="!edit">
      <button
        class="btn-add"
        @click.prevent="sideBarLine"
        :disabled="disableStatus"
      >
        <font-awesome-icon icon="plus-square" class="mr-2" />
        <span class="add-new">Create a New List</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    edit: {
      required: true,
      type: Boolean,
    },
    value: {
      required: true,
      type: Array,
    },
    disableStatus: {
      required: false,
      type: Boolean,
    },
  },
  methods: {
    sideBarLine() {
      this.$emit("sideBarOpen");
    },
    deleteLine() {
      this.$emit("deleteMessage", 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-commu {
  background-color: #f5f5f5;
  padding: 5px 15px;
}
.card-commu-edit {
  background-color: #f5f5f5;
  padding: 5px 15px 42px 15px;
}
.font-w-600 {
  font-weight: 600;
}
.container-card {
  height: 150px;
  position: relative;
}
.container-card-list {
  background-color: #fff;
  padding: 10px;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-underline {
  text-decoration: underline;
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.btn-edit {
  background-color: transparent;
  border: none;
  color: gray;
}
.scroll-content {
  overflow-y: scroll;
  overflow-x: hidden;
}
.bg-button {
  background-color: var(--secondary-color);
  color: #7160c4;
  border-color: var(--secondary-color);
  font-size: 14px;
  border-radius: 5px;
  padding: 10px;
}
::v-deep .svg-inline--fa.fa-w-16 {
  width: 0.75em;
}
.crop-img {
  position: relative;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px dashed #bebebe;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.b-white {
  background-color: #fff;
}
</style>
